@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,700');

.App { 
  font-family: "Titillium Web", sans-serif;
}

.App-header {
  color: white;
  min-height: 100px;
  text-transform: uppercase;
}

.App-header a {
  border-bottom: .3rem solid transparent;
}

.App-header a:hover:not([href='/']) {
  border-bottom: .3rem solid pink;
}

.App-logo {
  max-width: 300px;
}

.App-main {
  font-size: calc(10px + 1.5vmin);
  min-height: calc(100vh - 100px - 150px);
  padding: 0 1rem;
}

.App-main .breadcrumb {
  color: pink !important;
  font-size: 1rem;
}

.App-footer {
  min-height: 150px;
  background-color: #282c34;
  color: white;
  padding: 1rem;
  text-transform: uppercase;
  display: flex;
}

.App-footer .nav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.App-footer .nav-item a {
  color: pink !important;
  border-left: .1rem solid transparent;
  text-decoration: none;
  padding-left: 1rem;
}

.App-footer .nav-item a:hover {
  border-left: .1rem solid hotpink;
}

.App-footer-copyright {
  align-self: flex-end;
}

.App-coming-soon {
  text-align: center
}

